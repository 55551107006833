
/* tslint:disable */
/* eslint-disable */
/**
 * CMS API
 * Content management API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type {FetchOptions} from 'ofetch';
import {createUniversalFetchRaw, OFetchError} from "#imports";

const useUniversalFetchRaw = createUniversalFetchRaw({baseURL: '/'})

export const BASE_PATH = "https://dev.malt.fr".replace(/\/+$/, "");

export interface ConfigurationParameters {
    basePath?: string; // override base path
    queryParamsStringify?: (params: HTTPQuery) => string; // stringify function for query strings
    headers?: HTTPHeaders; //header params we want to use on every request
}

export class Configuration {
    constructor(private configuration: ConfigurationParameters = {}) {}

    set config(configuration: Configuration) {
        this.configuration = configuration;
    }

    get basePath(): string {
        return this.configuration.basePath != null ? this.configuration.basePath : BASE_PATH;
    }


    get queryParamsStringify(): (params: HTTPQuery) => string {
        return this.configuration.queryParamsStringify || querystring;
    }


    get headers(): HTTPHeaders | undefined {
        return this.configuration.headers;
    }

}

export const DefaultConfig = new Configuration();

/**
 * This is the base class for all generated API classes.
 */
export class BaseAPI {

    private static readonly jsonRegex = new RegExp('^(:?application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(:?;.*)?$', 'i');
    constructor(protected configuration = DefaultConfig) {
    }


    /**
     * Check if the given MIME is a JSON MIME.
     * JSON MIME examples:
     *   application/json
     *   application/json; charset=UTF8
     *   APPLICATION/JSON
     *   application/vnd.company+json
     * @param mime - MIME (Multipurpose Internet Mail Extensions)
     * @return True if the given MIME is JSON, false otherwise.
     */
    protected isJsonMime(mime: string | null | undefined): boolean {
        if (!mime) {
            return false;
        }
        return BaseAPI.jsonRegex.test(mime);
    }

    protected async request<T>(context: RequestOpts, initOverrides?: FetchOptions | InitOverrideFunction): Promise<FetchResponse<T>> {
        const { url, init } = this.createFetchParams(context, initOverrides);
        const response = await this.fetchApi<T>(url, init);

        if (response && (response.status >= 200 && response.status < 300)) {
            return response;
        }
        throw new ResponseError(response, 'Response returned an error code');
    }

    private  createFetchParams(context: RequestOpts, initOverrides?: FetchOptions | InitOverrideFunction) {
        let url = this.configuration.basePath + context.path;
        if (context.query !== undefined && Object.keys(context.query).length !== 0) {
            // only add the querystring to the URL if there are query parameters.
            // this is done to avoid urls ending with a "?" character which buggy webservers
            // do not handle correctly sometimes.
            url += '?' + this.configuration.queryParamsStringify(context.query);
        }

        const headers = Object.assign({}, this.configuration.headers, context.headers);
        Object.keys(headers).forEach(key => headers[key] === undefined ? delete headers[key] : {});



        const initParams = {
            method: context.method,
            headers,
            body: context.body,
        };

        const overriddenInit: FetchOptions = {
            ...initParams,
            ...initOverrides
        };

        let body: any;
        if (isFormData(overriddenInit.body)
            || (overriddenInit.body instanceof URLSearchParams)
            || isBlob(overriddenInit.body)) {
          body = overriddenInit.body;
        } else if (this.isJsonMime(headers['Content-Type'])) {
          body = JSON.stringify(overriddenInit.body);
        } else {
          body = overriddenInit.body;
        }

        const init: FetchOptions = {
            ...overriddenInit,
            body
        };

        return { url, init };
    }

    private convertMethod = (method: string | undefined) => {
        switch (method) {
            case 'GET':
              return 'GET';
            case 'POST':
              return 'POST';
            case 'PUT':
              return 'PUT';
            case 'DELETE':
              return 'DELETE';
            case 'PATCH':
              return 'PATCH';
            case 'HEAD':
              return 'HEAD';
            case 'OPTIONS':
              return 'OPTIONS';
            default:
              return 'GET';
        }
    }

    private fetchApi = async <T>(url: string, init: FetchOptions): Promise<FetchResponse<T>> => {
        let fetchParams = { url, init };
        let response: FetchResponse<T> | undefined = undefined;
        try {
            const url = fetchParams.url;
            const method = this.convertMethod(init.method);
            const headers = init.headers;
            const body = init.body;
            const signal = init.signal;
            const timeout = init.timeout;
            response = await useUniversalFetchRaw(url, {method, headers, body, signal, timeout}) as FetchResponse<T> | undefined;
        } catch (e) {
            if (response === undefined) {
              if (e instanceof Error) {
                throw new FetchError(e, `The request failed with error: ${e.message}`);
              } else {
                throw e;
              }
            }
        }
        if (response === undefined) {
            throw new FetchError(new Error('Response from the API is undefined'));
        }
        return response;
    }

    /**
     * Create a shallow clone of `this` by constructing a new instance
     * and then shallow cloning data members.
     */
    private clone<T extends BaseAPI>(this: T): T {
        const constructor = this.constructor as any;
        const next = new constructor(this.configuration);
        return next;
    }
};

function isBlob(value: any): value is Blob {
    return typeof Blob !== 'undefined' && value instanceof Blob;
}

function isFormData(value: any): value is FormData {
    return typeof FormData !== "undefined" && value instanceof FormData;
}

export class ResponseError extends Error {
    override name: "ResponseError" = "ResponseError";
    constructor(public response: Response, msg?: string) {
        super(msg);
    }
}

export class FetchError extends Error {
    override name: "FetchError" = "FetchError";
    constructor(public override cause: Error, msg?: string) {
        super(msg);
    }

    hasValidationErrors(): boolean {
        return Array.isArray(this.getCauseData());
    }
    
    getValidationErrors(t : Function): {[key: string]: string[]} {
        const data = this.getCauseData();
        if (Array.isArray(data)) {
            const errors: {[key: string]: string[]} = {};

            data.forEach((error) => {
                const {attribute, error: errorMessage} = error;
                const formattedErrorMessage = errorMessage.replace('{', '').replace('}', '');
                if (!errors[attribute]) {
                    errors[attribute] = [];
                }
                errors[attribute].push(t(formattedErrorMessage));
            });
            return errors;
        }
        return {};
    }

    getCauseData(): string | object | undefined {
        if (this.cause instanceof OFetchError) {
            return this.cause.data;
        }
        return undefined;
    }

    get statusCode(): number | undefined {
        if (this.cause instanceof OFetchError) {
            return this.cause.statusCode;
        }

        return undefined;
    }
}

export class RequiredError extends Error {
    override name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};


export type Json = any;
export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD';
export type HTTPHeaders = { [key: string]: string };
export type HTTPQuery = { [key: string]: string | number | null | boolean | Array<string | number | null | boolean> | Set<string | number | null | boolean> | HTTPQuery };
export type HTTPBody = Json | FormData | URLSearchParams;
export type HTTPRequestInit = { headers?: HTTPHeaders; method: HTTPMethod; credentials?: RequestCredentials; body?: HTTPBody };
export type ModelPropertyNaming = 'camelCase' | 'snake_case' | 'PascalCase' | 'original';

export type InitOverrideFunction = (requestContext: { init: HTTPRequestInit, context: RequestOpts }) => Promise<FetchOptions>

export interface FetchParams {
    url: string;
    init: FetchOptions;
}

export interface RequestOpts {
    path: string;
    method: HTTPMethod;
    headers: HTTPHeaders;
    query?: HTTPQuery;
    body?: HTTPBody;
}

export function exists(json: any, key: string) {
    const value = json[key];
    return value !== null && value !== undefined;
}

export function querystring(params: HTTPQuery, prefix: string = ''): string {
    return Object.keys(params)
        .map(key => querystringSingleKey(key, params[key], prefix))
        .filter(part => part.length > 0)
        .join('&');
}

function querystringSingleKey(key: string, value: string | number | null | undefined | boolean | Array<string | number | null | boolean> | Set<string | number | null | boolean> | HTTPQuery, keyPrefix: string = ''): string {
    const fullKey = keyPrefix + (keyPrefix.length ? `[${key}]` : key);
    if (value instanceof Array) {
        const multiValue = value.map(singleValue => encodeURIComponent(String(singleValue)))
            .join(`&${encodeURIComponent(fullKey)}=`);
        return `${encodeURIComponent(fullKey)}=${multiValue}`;
    }
    if (value instanceof Set) {
        const valueAsArray = Array.from(value);
        return querystringSingleKey(key, valueAsArray, keyPrefix);
    }
    if (value instanceof Date) {
        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`;
    }
    if (value instanceof Object) {
        return querystring(value as HTTPQuery, fullKey);
    }
    return `${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`;
}

export function mapValues(data: any, fn: (item: any) => any) {
  return Object.keys(data).reduce(
    (acc, key) => ({ ...acc, [key]: fn(data[key]) }),
    {}
  );
}

export function canConsumeForm(consumes: Consume[]): boolean {
    for (const consume of consumes) {
        if ('multipart/form-data' === consume.contentType) {
            return true;
        }
    }
    return false;
}

export interface Consume {
    contentType: string;
}

export interface RequestContext {
    url: string;
    init: FetchOptions;
}

export interface ResponseContext {
    url: string;
    init: FetchOptions;
    response: Response;
}

export interface ErrorContext {
    url: string;
    init: FetchOptions;
    error: unknown;
    response?: Response;
}


export interface FetchResponse<T> extends Response {
    _data: T;
}

export interface ApiResponse<T> {
    raw: Response;
    value(): Promise<T>;
}

export interface ResponseTransformer<T> {
    (json: any): T;
}

export class JSONApiResponse<T> {
    constructor(public raw: Response, private transformer: ResponseTransformer<T> = (jsonValue: any) => jsonValue) {}

    async value(): Promise<T> {
        return this.transformer(await this.raw.json());
    }
}

export class VoidApiResponse {
    constructor(public raw: Response) {}

    async value(): Promise<void> {
        return undefined;
    }
}

export class BlobApiResponse {
    constructor(public raw: Response) {}

    async value(): Promise<Blob> {
        return await this.raw.blob();
    };
}

export class TextApiResponse {
    constructor(public raw: Response) {}

    async value(): Promise<string> {
        return await this.raw.text();
    };
}
